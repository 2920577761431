import Paragraph from "@/lib/Layout/Wrappers/Paragraph";
import Section from "@/lib/Layout/Wrappers/Section";
import React from "react";
import { joinOurProgramData } from "../../pages/api/joinOurProgramData";
import FadeIn from "@/lib/AnimationComponents/FadeIn";
import router from "next/router";
import Image from "next/image";
import { Button, Show } from "@chakra-ui/react";
import { Numbers } from "./Numbers";

const JoinOurProgram = ({
  joinOurProgramData,
}: {
  joinOurProgramData: joinOurProgramData;
}) => {
  return (
    <Section className="flex flex-col justify-between md:flex-row">
      <div className="flex flex-col gap-8 justify-center items-center md:items-start">
        <FadeIn delay={0.01}>
          <p className="text-center text-6xl font-semibold">
            {joinOurProgramData?.heading}
          </p>
        </FadeIn>
        <FadeIn delay={0.3}>
          <Paragraph className="text-center md:text-left">
            {joinOurProgramData?.paragraph}
          </Paragraph>
        </FadeIn>
        <Show below="sm">
          <div className="w-[22rem] h-[365px] relative rounded-[1.8rem]">
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
              className="rounded-[1.8rem]"
            >
              <Image
                src="https://assets.bonatra.com/package_detail/Agna.webp"
                alt="health coach"
                fill
                style={{ objectFit: "cover" }}
                className="object-cover rounded-[1.8rem]"
                sizes="(max-width: 768px) 80vw,
              (max-width: 1200px) 40vw,
              30vw"
              />
            </div>
          </div>
        </Show>
        <Numbers data={joinOurProgramData?.data} />
        <div className="flex gap-4 items-center ">
          <Button
            bg="black"
            fontSize={"xl"}
            color="white"
            _hover={{ bg: "gray.700" }}
            p={8}
            onClick={() =>
              router.push({
                pathname: "/all-products",
              })
            }
          >
            View Plans
          </Button>
        </div>
      </div>

      <Show above="md">
        <div className="w-64 md:w-[440px] h-[565px] relative rounded-[1.8rem]">
          <div className="absolute top-0 left-0 right-0 bottom-0 rounded-[1.8rem]">
            <Image
              src="https://assets.bonatra.com/package_detail/Agna.webp"
              alt="health coach"
              fill
              style={{ objectFit: "cover" }}
              className="object-cover rounded-[1.8rem]"
              sizes="(max-width: 768px) 80vw,
              (max-width: 1200px) 40vw,
              30vw"
            />
          </div>
        </div>
      </Show>
    </Section>
  );
};

export default JoinOurProgram;
