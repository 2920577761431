import { animate, useInView } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'


const NumberAnimate = ({ start, stop, preText, postText, subText, className }: { className?: string, start: number, stop: number, preText: string, postText: string, subText?: string }) => {

  const [number, setNumber] = useState(start)
  const numberRef = useRef(null)
  const isInView = useInView(numberRef)

  useEffect(() => {

    const controls = animate(0, stop, {
      onUpdate: latest => setNumber(Math.round(latest))
      , duration: 2
    })
    return () => { controls.stop; setNumber(0) }
  }, [stop, isInView])

  return (
    <div className={`font-bold text-5xl text-black flex gap-4 items-end md:w-auto ${className}`} ref={numberRef}>{preText + number + postText}<p className='text-sm font-normal'>{subText}</p></div>
  )
}

export default NumberAnimate