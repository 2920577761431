import NumberAnimate from "@/lib/AnimationComponents/NumberAnimate";
import Paragraph from "@/lib/Layout/Wrappers/Paragraph";
import { allNumberData, numberData } from "@/pages/api/joinOurProgramData";

export const Numbers = ({ data }: { data: allNumberData }) => {
  return (
    <div className="flex gap-8 flex-wrap justify-center md:justify-start items-center">
      {data.map((cardData, idx) => (
        <NumberCard key={idx} cardData={cardData} />
      ))}
    </div>
  );
};

const NumberCard = ({ cardData }: { cardData: numberData }) => {
  return (
    <div className="flex flex-col gap-4 justify-center md:justify-start">
      <p className="font-bold text-5xl " ></p>
      <div className="flex flex-col justify-start items-start w-[120px] lg:w-[150px] lg:h-[100px]">
        <NumberAnimate
          start={0}
          stop={cardData.number}
          preText={cardData.preText}
          postText={cardData.postText}
          subText={cardData.subText}
        />
        <Paragraph className="font-normal text-left ">{cardData.text}</Paragraph>
      </div>
    </div>
  );
};